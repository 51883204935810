import React from 'react';
import { Link } from 'react-router-dom'
//import { Link,withRouter, history} from 'react-router-dom'
//import { DropdownMenu, MenuItem } from 'react-bootstrap-dropdown-menu';
import { connect } from 'react-redux'
import agent from '../agent'
import { Cookies } from 'react-cookie'
import {LOGIN,TOKEN_NAME,CHANGE_TAB, LOGOUT, APP_LOAD, JOIN_CLASS_DATA, LANGUAGE_KEY} from '../constants/actionTypes'
import '../styles/main.css'
import '../styles/responsive.css'
import {Dropdown} from 'react-bootstrap'
import $ from 'jquery';
import Avatar from 'react-avatar';
//import { datadogRum } from '@datadog/browser-rum';
import firebase from '../firebase'
import ModalErorr from './common/ModalErorr';
import Menu from './language/menu.js';
import LocalizedStrings from 'react-localization';
import ReactLoading from 'react-loading';


const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
		join_class_data: state.common.join_class_data,
	})      
);

const mapDispatchToProps = dispatch => ({  
	onLogin: (redirectTo, token) => {
		dispatch({ type: LOGIN, redirectTo, token})  
	},
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	setToken: (data)=>{ 
		dispatch({ type: TOKEN_NAME, data })
	},
	setJoinClass: (data)=>{ 
		dispatch({ type: JOIN_CLASS_DATA, data })
	},
	onLoad: (access_token) =>
		dispatch({ type: APP_LOAD, access_token }),
	onLogout: () =>
		dispatch({type: LOGOUT}),
	onResetLanguesKey: (data) => {
		dispatch({ type: LANGUAGE_KEY, data})  
	},
});
let sMenu=new LocalizedStrings(Menu);
let host = window.location.hostname;
let href = window.location.href.includes('/login') ? '' : window.location.href;

class Header extends React.Component {
	constructor(){
		super()
		this.state = {
			search:'',username:'',schools:[],school_logo:'',school_name:'',photo:'',digital_seal:'',
			gradebook: 0, attendance: 0, learning_path: 0, email: 0, resources: 0,dataRole:[],user_id:'',
			last_role:'',isOpenModal:false,msg:'',last_school_id:'',impersonate_data:'',sections:[],
			impersonate_info:'',impersonate_role:'',readroster:false,updateroster:false,
			listAccess:[],updategradebook:false,
			readgradebook:false,supporticketmanager:false,superschooladmin:false,
			help_link:'https://advantages-dls.zendesk.com/hc/en-us',language:'en',dataLangues:[],
			readattendance:false,updateattendance:false,supportmultilingual:false,
			customreportviewer:true,gotoLogin:false,asiroster:false,loaderspiner:false,
			viewmessaging: false,readlearningpathtemplates: false,viewattendance: false,viewresources: false,viewgradebook: false, viewdiscussionforum: false, participatediscussionforum: false, total_count_disccusion: 0,
		}
		//---
		this.close=()=>{
			let gotoLogin=this.state.gotoLogin;
			this.setState({isOpenModal:false,msg:''},()=>{
				if(gotoLogin==true){
					window.location.href = '/login'
				}
			})
		}
		this.zendesksso=()=>{
			agent.admin.zendesksso({}).then(datas => {				
				if(datas){					
					window.open(datas, '_blank');
				}
					
			});
		}
		this.getUserSchools=(id)=>{
			agent.auth.getUserSchools({}).then(datas => {
				if(datas.status===true){
					//let results=datas.results;
					let results=datas.data;
					window.localStorage.setItem('SCHOOLLIST',JSON.stringify(results))
					let school_name='';let school_logo='';let digital_seal='';let help_link='';
					let schools=results.map(dt=>{
						if(parseFloat(dt.school_id)==id){
							school_name=dt.school_name;
							school_logo=dt.school_logo;
							digital_seal=dt.digital_seal;
							help_link=dt.help_link;
						}
						return dt;
					})
					
					this.setState({schools:schools,school_name:school_name,school_logo:school_logo,digital_seal:digital_seal,help_link:help_link},()=>{
					
					})
				}
			})
		}
		this.unimpersonate=(e)=>{
			this.setState({loaderspiner:true})
			agent.admin.unimpersonate({impersonate_data:this.state.impersonate_data,}).then(datas => {
				if(datas.status===true){
					
					let UserInfo=datas.data;
					let last_role=parseInt(UserInfo.last_role);
					window.localStorage.setItem('last_role',last_role);
					const cookies = new Cookies()
					const expires_in = datas.expires_in / 60 / 60 / 24
					let dat = new Date()
					//dat.setDate(dat.getDate() + expires_in)	
					dat.setTime(dat.getTime() + (1*24*60*60*1000));	
					//cookies.set(TOKEN_NAME, UserInfo.token, {expires: dat})
					this.setCookie(TOKEN_NAME, UserInfo.token, 1)
					window.localStorage.setItem('TOKEN_NAME',UserInfo.token)
					cookies.set('USERLOG', UserInfo, {expires: dat})
					this.setCookie('USERLOG', UserInfo, 1)
					//console.log(UserInfo)
					//window.localStorage.setItem('USERLOG',JSON.stringify(UserInfo))
					let name_log=(last_role===1)?'USERLOGSUP':'USERLOG';
					window.localStorage.setItem(name_log,JSON.stringify(UserInfo));
					let name=UserInfo.fname;
					name=name.split(" ");
					window.localStorage.setItem('NAMEINFO',name[0])
					window.localStorage.setItem('EMAIL',UserInfo.email)
					//agent.setToken(UserInfo.token)
					//this.props.onLogin('home',UserInfo.token);	
					//this.setState({school_name:UserInfo.school_name})	
					//this.getUserSchools(school_id);
					//this.get_user_role();
					//window.location.href = '/home';
					this.setState({loaderspiner:false})
					//setTimeout(() => {
						  var url=window.localStorage.getItem('url_before_imper');
						  url=(url!=undefined && url!='')?url:'/user-directory';
						  window.location.href = url;
						//},1000)
						
					
					
				}else{
					this.setState({msg: datas.message,isOpenModal:true,gotoLogin:true,loaderspiner:false})
				}
					
			});
		}
		this.changeSchool=(school_id,e)=>{
			let host=window.location.hostname
			host= host.split(".")
			let hosti=(host!==undefined && host!==null && host[0]!==undefined)?host[0]:'';
			let role = (hosti === "teacher") ? 3 : (hosti === "sadmin" || hosti === "portal") ? 2 : (hosti === "sa") ? 1 : 2;
			agent.auth.changeSchool({school_id:school_id,role:role}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					//
					let UserInfo=datas.data;
					let last_role=parseInt(UserInfo.last_role);
					window.localStorage.setItem('last_role',last_role);
					/*if(last_role==2 && hosti!="sadmin" && hosti!="localhost" && hosti!='staging'){
						let sso_portal=UserInfo.sso_portal;
						let a= document.createElement('a');
						a.href= sso_portal;
						a.click();
					}else{*/
						const cookies = new Cookies()
						const expires_in = datas.expires_in / 60 / 60 / 24
						let dat = new Date()
						//dat.setDate(dat.getDate() + expires_in)	
						dat.setTime(dat.getTime() + (1*24*60*60*1000));	
						//cookies.set(TOKEN_NAME, UserInfo.token, {expires: dat})
						this.setCookie(TOKEN_NAME, UserInfo.token, 1)
						window.localStorage.setItem('TOKEN_NAME',UserInfo.token)
						cookies.set('USERLOG', UserInfo, {expires: dat})
						this.setCookie('USERLOG', UserInfo, 1)
						//console.log(UserInfo)
						//window.localStorage.setItem('USERLOG',JSON.stringify(UserInfo))
						let name_log=(last_role===1)?'USERLOGSUP':'USERLOG';
						window.localStorage.setItem(name_log,JSON.stringify(UserInfo));
						let name=UserInfo.fname;
						name=name.split(" ");
						window.localStorage.setItem('NAMEINFO',name[0])
						window.localStorage.setItem('EMAIL',UserInfo.email)
						//agent.setToken(UserInfo.token)
						//this.props.onLogin('home',UserInfo.token);	
						//this.setState({school_name:UserInfo.school_name})	
						//this.getUserSchools(school_id);
						//this.get_user_role();
						//window.location.href = '/home';
						setTimeout(() => {
							  //window.location.reload();
							  window.location.href = '/home';
							},1000)
						
					//}
					
				}else{
					this.setState({msg: datas.message,isOpenModal:true})
				}
					
			});
		}
		
		this.setCookie=(name,value,days)=> {
			const cookies = new Cookies()
			var expires = "";
			if (days) {
				var date = new Date();
				date.setTime(date.getTime() + (days*24*60*60*1000));
				//date.setTime(date.getTime() + (days*1*2*60*1000));
				expires = "; expires=" + date.toUTCString();
				console.log(date)
				cookies.set(name, value, {expires: date})
			}
			//document.cookie = name + "=" + (value || "")  + expires + "; path=/";
			
		}
		this.auto_login=(token)=>{
			agent.auth.auto_login(token).then(data => {
				//console.log(data)
				if(data.status === true){
					let UserInfo=data.data;
					let last_role=parseInt(UserInfo.last_role);
					window.localStorage.setItem('last_role',last_role);
					const cookies = new Cookies()
					const expires_in = data.expires_in / 60 / 60 / 24
					let dat = new Date()
					//dat.setDate(dat.getDate() + expires_in)	
					dat.setTime(dat.getTime() + (1*24*60*60*1000));	
					//cookies.set(TOKEN_NAME, UserInfo.token, {expires: dat})
					this.setCookie(TOKEN_NAME, UserInfo.token, 1)
					window.localStorage.setItem('TOKEN_NAME',UserInfo.token)
					cookies.set('USERLOG', UserInfo, {expires: dat})
					//this.setCookie('USERLOG', UserInfo, 1)
					//console.log(UserInfo)
					//window.localStorage.setItem('USERLOG',JSON.stringify(UserInfo))
					let name_log=(last_role===1)?'USERLOGSUP':'USERLOG';
					window.localStorage.setItem(name_log,JSON.stringify(UserInfo));
					let name=UserInfo.fname;
					name=name.split(" ");
					window.localStorage.setItem('NAMEINFO',name[0])
					window.localStorage.setItem('EMAIL',UserInfo.email)
					agent.setToken(UserInfo.token)
					this.props.onLogin('home',UserInfo.token);
						
					window.location.href = '/home'
				}
			})
		}
		/*this.user_change_role_bk=(role,e)=>{
			agent.auth.user_change_role({role:role}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					let UserInfo=datas.data;
					if(parseFloat(role)===3){
						const cookies = new Cookies()
						//const expires_in = data.expires_in / 60 / 60 / 24
						let dat = new Date()
						//dat.setDate(dat.getDate() + expires_in)	
						dat.setTime(dat.getTime() + (1*24*60*60*1000));	
						//cookies.set(TOKEN_NAME, UserInfo.token, {expires: dat})
						this.setCookie(TOKEN_NAME, UserInfo.token, 1)
						window.localStorage.setItem('TOKEN_NAME',UserInfo.token)
						cookies.set('USERLOG', UserInfo, {expires: dat})
						//this.setCookie('USERLOG', UserInfo, 1)
						//console.log(UserInfo)
						window.localStorage.setItem('USERLOG',JSON.stringify(UserInfo))
						let name=UserInfo.fname;
						name=name.split(" ");
						window.localStorage.setItem('NAMEINFO',name[0])
						window.localStorage.setItem('EMAIL',UserInfo.email)
					
						window.location.reload();
					}else if( parseFloat(role)===4 || parseFloat(role)===5){
						//this.auto_login(UserInfo.token);
						let url='https://portal.adlsassist.com/login?token='+UserInfo.token;
						let a= document.createElement('a');
						a.href= url;
						a.click();
					}else{
						let sso_portal=UserInfo.sso_portal;
						let a= document.createElement('a');
						a.href= sso_portal;
						a.click();
					}
					
				}
					
			});
		}
		*/
		this.user_change_role=(role,e)=>{
			let host=window.location.hostname
			host= host.split(".")
			let hosti=(host!==undefined && host!==null && host[0]!==undefined)?host[0]:''
			//console.log(role,host)	
			
			agent.auth.user_change_role({role:role}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					let UserInfo=datas.data;
					let last_role=parseInt(UserInfo.last_role);
					window.localStorage.setItem('last_role',last_role);
					/*if(parseFloat(role)==2 && hosti!="sadmin" && hosti!="localhost" && hosti!='staging'){
						let sso_portal=UserInfo.sso_portal;
						let a= document.createElement('a');
						a.href= sso_portal;
						a.click();
					}else{*/
						const cookies = new Cookies()
						//const expires_in = data.expires_in / 60 / 60 / 24
						let dat = new Date()
						//dat.setDate(dat.getDate() + expires_in)	
						dat.setTime(dat.getTime() + (1*24*60*60*1000));	
						//cookies.set(TOKEN_NAME, UserInfo.token, {expires: dat})
						this.setCookie(TOKEN_NAME, UserInfo.token, 1)
						window.localStorage.setItem('TOKEN_NAME',UserInfo.token)
						cookies.set('USERLOG', UserInfo, {expires: dat})
						//this.setCookie('USERLOG', UserInfo, 1)
						//console.log(UserInfo)
						//window.localStorage.setItem('USERLOG',JSON.stringify(UserInfo))
						let name_log=(last_role===1)?'USERLOGSUP':'USERLOG';
						window.localStorage.setItem(name_log,JSON.stringify(UserInfo));
						let name=UserInfo.fname;
						name=name.split(" ");
						window.localStorage.setItem('NAMEINFO',name[0])
						window.localStorage.setItem('EMAIL',UserInfo.email)
					
						window.location.href = '/home';
					//}
					
				}
					
			});
		}
    this.get_discussion_section = () => {
      let params = {
        school_id: this.state.last_school_id
      };
      this.setState({ loading: true });
      agent.chat.get_discussion_section(params).then(datas => {
        // this.setState({ loading: false });
        if (datas.status === true) {
          let data = datas.data;
					let total_count = 0;
					data.forEach(e => {
						total_count += e.count_new;
					});
          this.setState({
            sections: data,
						total_count_disccusion: total_count,
          }, () => {
          });
        }
      })
    }
		
		this.get_user_role=(token,e)=>{
			//console.log(school_id)
			agent.auth.get_user_role({}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					//console.log(datas)
					this.setState({dataRole:datas.data})
				}
					
			});
		}
		this.setDevice=(token,e)=>{
			//console.log(school_id)
			agent.auth.setDevice({token:token,type:'web'}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					//console.log(datas)
				}
					
			});
		}
		
		this.get_content_roles_sso=()=>{
			agent.admin.get_content_roles_sso({}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					let data=datas.data;
					this.setState({listAccess:data})
				}
					
			});
		}
		this.log_sso=(url,e)=>{
			let a= document.createElement('a');
			a.target= '_blank';
			a.href= url;
			a.click();
		}
		this.get_configuration = () => {
			agent.admin.get_configuration({}).then(datas => {
				//console.log(datas)
				if (datas.status === true) {
					let data = datas.data;
					if (!data) return;
					let user_settings = data.user_settings;
					if (user_settings != undefined && user_settings != null) {
						let readroster = user_settings.readroster;
						let updateroster = user_settings.updateroster;
						let updategradebook = user_settings.updategradebook;
						let readgradebook = user_settings.readgradebook;
						let superschooladmin = user_settings.superschooladmin;
						let supporticketmanager = user_settings.supporticketmanager;
						let readattendance = user_settings.readattendance;
						let updateattendance = user_settings.updateattendance;
						let supportmultilingual = user_settings.supportmultilingual;
						let asiroster = user_settings.asiroster;
						let viewdiscussionforum = user_settings.viewdiscussionforum;
						let participatediscussionforum = user_settings.participatediscussionforum;
						let viewmessaging = user_settings.hasOwnProperty('viewmessaging') ? user_settings.viewmessaging : false;
						let readlearningpathtemplates = user_settings.hasOwnProperty('readlearningpathtemplates') ? user_settings.readlearningpathtemplates : false;
						let viewattendance = user_settings.hasOwnProperty('viewattendance') ? user_settings.viewattendance : false;
						let viewresources = user_settings.hasOwnProperty('viewresources') ? user_settings.viewresources : false;
						let viewgradebook = user_settings.hasOwnProperty('viewgradebook') ? user_settings.viewgradebook : false;
						window.localStorage.setItem('user_settings', JSON.stringify(user_settings));
						this.setState({
							readroster: readroster,
							updateroster: updateroster,
							readgradebook: readgradebook,
							updategradebook: updategradebook,
							supporticketmanager: supporticketmanager,
							superschooladmin: superschooladmin,
							readattendance: readattendance,
							updateattendance: updateattendance,
							supportmultilingual: supportmultilingual,
							asiroster: asiroster,
							viewdiscussionforum: viewdiscussionforum,
							participatediscussionforum: participatediscussionforum,
							viewmessaging: viewmessaging,
							readlearningpathtemplates: readlearningpathtemplates,
							viewattendance: viewattendance,
							viewresources: viewresources,
							viewgradebook: viewgradebook,
						})
					}

				} else {
					if (host != 'localhost') {
						window.sessionStorage.setItem('previous_href', href);
						window.location.href = '/login'
					}
				}
			})
		}
		//--
		this.toggle=(e)=>{
			$(".b").toggle();
		}
		this.get_languages=()=>{
			let language=this.state.language;
			agent.home.get_languages({}).then(datas => {
				if(datas.status===true){
					//console.log(datas)
					this.setState({dataLangues:datas.data},()=>{
						let thiss=this;
						//==== Start Language Drowdown Js
						var langArray = [];
						var langArrayChoi = [];
						$('.vodiapicker option').each(function(){
							var img = $(this).attr("datathumbnail");
							var text = this.innerText;
							var value = $(this).val();
							var item = '<li><img src="'+ img +'" alt="" value="'+value+'"/><span>'+ text +'</span></li>';
							langArray.push(item);
							if(language==value){
								langArrayChoi.push(item);
								//console.log(langArrayChoi)
							}
						})
						$('#a').html(langArray);
						$('.btn-select').html(langArrayChoi[0]);
						$('.btn-select').attr('value', language);
						$('#a li').click(function(){
							var img = $(this).find('img').attr("src");
							var value = $(this).find('img').attr('value');
							var text = this.innerText;
							var item = '<li><img src="'+ img +'" alt="" /><span>'+ text +'</span></li>';
							$('.btn-select').html(item);
							$('.btn-select').attr('value', value);
							thiss.handleLanguageChange(value);
							$(".b").toggle();
							//alert(value)
						});

						$(".btn-select").click(function(){
							$(".b").toggle();
						});

						var sessionLang = localStorage.getItem('lang');
						if (sessionLang){
						  var langIndex = langArray.indexOf(sessionLang);
						  $('.btn-select').html(langArray[langIndex]);
						  $('.btn-select').attr('value', sessionLang);
						} else {
						  var langIndex = langArray.indexOf('ch');
						  //console.log(langIndex);
						  $('.btn-select').html(langArray[langIndex]);
						}
						//==== End Language Drowdown Js
					})
				}
					
			});
		}
		this.handleLanguageChange=(lang)=>{
			//console.log(lang)
			this.setState(prevState => ({
			  language: lang
			}))
			window.localStorage.setItem('language',lang);
			this.save_language(lang);
		}
		this.save_language=()=>{
			agent.home.save_language({language:this.state.language}).then(datas => {
				if(datas.status===true){
					this.props.onResetLanguesKey(this.state.language);
				}
			})
		}
		
		this.goToDiscussion = (section_id, e) => {
			window.localStorage.setItem('DISCUSSION_SECTION_ID', section_id)
			window.location.href='/discussion-thread'
		}
		//--
		
	}
	onWindowFocus(){
		//console.log("tetst focus")
	}
	onWindowBlur(){
		//alert("tetst blur")
	}
	//---------------------------
	 
	componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		if (nextProps.common.redirectTo) {
		  const token = window.localStorage.getItem(TOKEN_NAME)
		  const nextRedirect = nextProps.common.redirectTo
		  if(token && nextRedirect === '/login'){ // login page
			nextProps.history.push('/')	
		  } else if(token){       		
			nextProps.history.push(nextProps.common.redirectTo)                  
		  } else {
			//nextProps.history.push('/login') 
		  }
		  
		 // this.props.onRedirect();
		}
	}	

	componentWillMount() {
		//-----------------------
		this.get_configuration();
		const cookies = new Cookies()
		//const token = cookies.get(TOKEN_NAME);
		const token=window.localStorage.getItem('TOKEN_NAME');
		if(token && token!==undefined  && token!==null && token!==''){			
			agent.setToken(token)
			//const data = JSON.parse(token)
			this.props.onLoad( token) 
			//this.props.onLoad( data.UserInfo , data.access_token)       
		}else{
			window.location.href='/login';
		}
		//let userInfo=cookies.get('USERLOG');console.log(userInfo)
		let userInfo=window.localStorage.getItem('USERLOG');
		userInfo=JSON.parse(userInfo);console.log(userInfo);
		let languages=window.localStorage.getItem('language');
		
		if(userInfo!==''&& userInfo!==undefined){
			let fname=userInfo.fname
			let lname=userInfo.lname
			let mname=userInfo.mname
			let photo=userInfo.photo
			let user_id=userInfo.user_id
			let school_name=userInfo.school_name
			let last_role=userInfo.last_role
			let last_school_id=userInfo.last_school_id
			let impersonate_data=userInfo.impersonate_data
			let impersonate_info=userInfo.impersonate_info
			let impersonate_role=userInfo.impersonate_role
			let setting_config=userInfo.setting_config
			let readroster=false;
			let updateroster=false;
			let updategradebook=false;
			let readgradebook=false;
			let supporticketmanager=false;
			let superschooladmin=false;
			if(setting_config!=undefined && setting_config!=null && setting_config!=''){
				readroster=setting_config.readroster;
				updateroster=setting_config.updateroster;
				updategradebook=setting_config.updategradebook;
				readgradebook=setting_config.readgradebook;
				supporticketmanager=setting_config.supporticketmanager;
				superschooladmin=setting_config.superschooladmin;
				
			}
			/*let school_name='';let school_logo='';
			let schools=userInfo.schools.map(dt=>{
				if(dt.school_id===userInfo.last_school_id){
					school_name=dt.school_name;
					school_logo=dt.school_logo;
				}
				return dt;
			})*/
			//console.log(userInfo)
			this.setState({username:lname+", "+fname+" "+mname,photo:photo,user_id:user_id,
							school_name:school_name,last_role:parseFloat(last_role),
							last_school_id:parseFloat(last_school_id),
							impersonate_data:impersonate_data,
							impersonate_info:impersonate_info,
							impersonate_role:impersonate_role,
							readroster:readroster,
							updateroster:updateroster,
							updategradebook:updategradebook,
						    readgradebook:readgradebook,
						    supporticketmanager:supporticketmanager,
						    superschooladmin:superschooladmin,
							},()=>{
					this.getUserSchools(parseInt(userInfo.last_school_id));
					this.get_user_role();
					this.get_discussion_section();
					//this.get_content_roles_sso();
				})
			let hosts=window.location.hostname
			hosts= hosts.split(".")
			let hostis=(hosts!==undefined && hosts!==null && hosts[0]!==undefined)?hosts[0]:''
			//if(hostis!="portal" && hostis!="teacher"){
				this.get_content_roles_sso();
			//}	
			let language=userInfo.language;
			if(languages!=undefined && languages!=null && languages!=''){
				this.setState({language:languages},()=>{
					this.get_languages();
					window.localStorage.setItem('language',languages);
				});
			}else{
				this.get_languages();
			}
		}else{
			window.location.href='/login';
		}
		
		
		
		
	}	
	
	componentDidMount() {
		//==== Start Mobile menu js 
		$('.mobile-menu-icon').click(function(){
		  $('.mobile_main_menu_content .mu_accordion').slideToggle();
		});
		//==== End Mobile menu js 

		//==== Start Search Button Dropdown
		$('.mu_search_bar form button').click(function(){
		  $('.mu_dropdown').toggle();
		  return false;
		});
		$('body').click(function() {
		 $('.mu_dropdown').hide();
		});
		//==== End Search Button Dropdown

		//==== Start Alert Box Js
		$('.mu_alert_box_right a').click(function(){
		  $('.mu_alert_box').fadeOut();
		  return false;
		});
		$('.custom_alert_right a').click(function(){
		  $('.custom_alert_design').fadeOut();
		  return false;
		});
		//==== End Alert Box Js

		//==== Start Mobile Main Menu Js 
		$('.mobile-menu-icon').click(function(){
		  $('.header-bottom-content ul').slideToggle();
		});
		$('.mu_accordion').click(function(){
		  $('.mu_accordion').slideToggle();
		})
		//==== End Mobile Main Menu Js 

		//==== Start Progress Tracking Table Accordion Icon Js
		$(".rjdesign").click(function(e){
		  $(this).toggleClass("active");
		  e.preventDefault();
		});
		//==== End Progress Tracking Table Accordion Icon Js

		//==== Start Hide/Show Password Js
		$(".toggle-password").click(function() {
		  $(this).toggleClass("fa-eye fa-eye");
		  var input = $($(this).attr("toggle"));
		  if (input.attr("type") == "password") {
			input.attr("type", "text");
		  } else {
			input.attr("type", "password");
		  }
		});
		//==== Start Hide/Show Password Js

		//==== Start Recommendation Option Content Js
		$(".single-request-recommendation-option-content").click(function(){
		  $(".single-request-recommendation-option-content").removeClass("show");
		  $(this).addClass('show');
		});
		$(".request-recommendation-option-content:nth-child(2) .single-request-recommendation-option-area").click(function(){
		  $(".cross-button-image").toggle();
		});
		//==== End Recommendation Option Content Js

		//==== Start Popup Custom Js
		$(".st_single_action a").click(function(){
		  // return false;
		});
		$(".action_st_btn_img a.enrollment-show").click(function(){
		  $(".request-enrollment-area").fadeIn(10);
		});
		$(".same-close-button-left button,.single-same-button-design button.cancel").click(function(){
		  $(".request-enrollment-area").fadeOut(10);
		});

		$(".action_st_btn_img a.resources").click(function(){
		  $(".resources-area").fadeIn(10);
		});
		$(".same-close-button-left button").click(function(){
		  $(".resources-area").fadeOut(10);
		});

		$(".action_st_btn_img a.transcript").click(function(){
		  $(".request-transcript-area").fadeIn(10);
		});
		$(".same-close-button-left button").click(function(){
		  $(".request-transcript-area").fadeOut(10);
		});

		$(".action_st_btn_img a.recommendation").click(function(){
		  $(".request-recommendation-area").fadeIn(10);
		});
		$(".same-close-button-left button,.single-same-button-design button.cancel").click(function(){
		  $(".request-recommendation-area").fadeOut(10);
		});
		//==== Start Language Drowdown Js
		let thiss=this;
		var langArray = [];
		$('.vodiapicker option').each(function(){
			var img = $(this).attr("data-thumbnail");
			var text = this.innerText;
			var value = $(this).val();
			var item = '<li><img src="'+ img +'" alt="" value="'+value+'"/><span>'+ text +'</span></li>';
			langArray.push(item);
		})
		$('#a').html(langArray);
		$('.btn-select').html(langArray[0]);
		$('.btn-select').attr('value', 'en');
		$('#a li').click(function(){
			var img = $(this).find('img').attr("src");
			var value = $(this).find('img').attr('value');
			var text = this.innerText;
			var item = '<li><img src="'+ img +'" alt="" /><span>'+ text +'</span></li>';
			$('.btn-select').html(item);
			$('.btn-select').attr('value', value);
			thiss.handleLanguageChange(value);
			$(".b").toggle();
		});

		$(".btn-select").click(function(){
			$(".b").toggle();
		});

		var sessionLang = localStorage.getItem('lang');
		if (sessionLang){
		  var langIndex = langArray.indexOf(sessionLang);
		  $('.btn-select').html(langArray[langIndex]);
		  $('.btn-select').attr('value', sessionLang);
		} else {
		  var langIndex = langArray.indexOf('ch');
		  //console.log(langIndex);
		  $('.btn-select').html(langArray[langIndex]);
		}
		//==== End Language Drowdown Js
	}
	componentWillUnmount() {
	  //window.removeEventListener('resize', this.handleWindowSizeChange);
	}
	handleChange(field, e){
		this.setState({[field]: e.target.value})
	}
	handleDoLogout(){
		agent.auth.logout({}).then(datas => {
			console.log(datas)
		})
		let pathname=window.localStorage.getItem('pathname');
		const cooki = new Cookies()
		cooki.remove(TOKEN_NAME)
		this.props.onLogout()	
		//console.log(pathname)
		window.localStorage.setItem('join_class','')
		window.localStorage.setItem('USERLOG','');
		window.localStorage.setItem('TOKEN_NAME','');
		if(pathname!==undefined && pathname!=='' && pathname!==null){
			window.location.href = '/login'+pathname
		}else{
			window.location.href = '/login'
		}
		window.localStorage.setItem('language','');
		/*var cookies = document.cookie.split(";");

		for (var i = 0; i < cookies.length; i++) {
			var cookie = cookies[i];
			var eqPos = cookie.indexOf("=");
			var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
		}*/
	}
	generateColor () {
		return '#' +  Math.random().toString(16).substr(-6);
	}

	render() {
		const token = this.props.token
		const tab = this.props.tab
		let schools=this.state.schools
		let school_logo=this.state.school_logo
		let school_name=this.state.school_name
		let photo=this.state.photo
		let username=this.state.username
		let dataRole=this.state.dataRole
		let listAccess=this.state.listAccess
		let impersonate_data=this.state.impersonate_data;
		let readroster=this.state.readroster;
		let updateroster=this.state.updateroster;
		let updategradebook=this.state.updategradebook;
		let readgradebook=this.state.readgradebook;
		let supporticketmanager=this.state.supporticketmanager;
		let superschooladmin=this.state.superschooladmin;
		let supportmultilingual=this.state.supportmultilingual;

		let customreportviewer=this.state.customreportviewer;
		let asiroster=this.state.asiroster;

		//console.log(school_logo,this.state.school_name)
		let hosts=window.location.hostname
		hosts= hosts.split(".")
		let hostis=(hosts!==undefined && hosts!==null && hosts[0]!==undefined)?hosts[0]:''	
		let help_link=this.state.help_link;
		let dataLangues=this.state.dataLangues;
		sMenu.setLanguage(this.state.language);
		if(hostis!="localhost" && hostis!="staging" && hostis!="sa" && dataRole!==undefined && dataRole!==null && dataRole.length>1){
			dataRole=dataRole.filter(ti=>ti.role!=1);
		}else{
			dataRole=dataRole;
		}
		let role_login=window.localStorage.getItem('last_role');
		let last_role=parseInt(role_login)
		//---------
			return (
				<header className="header-area">           
					<div className="header-top-area">
						<div className="container">
							<div className={(this.state.language=='ar')?"header-top-content arabic":"header-top-content"}>
								<div className="header-top-left-content">
								
									<div className={(this.state.language=='ar')?"header-logo-dropdown arabic":"header-logo-dropdown"}>
										
										<Dropdown className={(this.state.language=='ar')?"arabic":""}>
										  <Dropdown.Toggle variant="success" id="dropdownMenuButton" className="dropdown-toggle shfocus logo-sch">
										  {
											(school_logo!=='')?<img className="h-80" src={(school_logo!=='')?school_logo:"/media/home/logo.png"} alt="Images" />:
											 <Avatar size="48" round={true} className="avatar-title" 
												color={'#00a2a7'} name={this.state.school_name} />
											}
											
												<h6 className="ml-3 mb-0 max-w-150px text-truncate">{school_name} </h6>
											
												<div className="down-icon"><img src="/media/home/down-arrow.png" alt="Images" /></div>
										  </Dropdown.Toggle>

										  <Dropdown.Menu className="dropdown-menu scrollere">
										  {
											  (schools!==null && schools!==undefined)?schools.map((dt,i)=>{
												  let ustatus=dt.user_status;
												  let message_status=(ustatus!=undefined && ustatus!=null && ustatus!='' && ustatus.message_status!=undefined)?ustatus.message_status:'';
												  let status=(ustatus!=undefined && ustatus!=null && ustatus!='')?ustatus.status:'';
												 
												  //Inactive
												  if(status!='Inactive'){
													  return(
														<Dropdown.Item href="" className={(this.state.last_school_id==parseFloat(dt.school_id))?"dropdown-item widths active":"dropdown-item widths"} key={i} onClick={this.changeSchool.bind(this,dt.school_id)} >
															<div>
																<span className="symbol symbol-20 mr-6">
																	<img src={dt.school_logo} alt=""/>
																</span>
																<h4 className="navi-text-school">{dt.school_name}</h4>
																<span className="navi-status-scholl" style={{'color':(ustatus!=undefined && ustatus!=null && ustatus!='' && ustatus.access==true)?'green':'red'}}>({status})</span>
																{(message_status!=undefined && message_status!=null && message_status!='')?<span className="massage-status"></span>:''}
															</div>
															{/*<span className="shoo-status-mes">{message_status}</span>*/}
														</Dropdown.Item>
													  )
												  }
											  }):''
										  }
											
										  </Dropdown.Menu>
										</Dropdown>
										
									</div>
									
									
									{/*<div className="header-flag-dropdown" style={{'display':'none'}}>
										<select className="vodiapicker">
											<option value="en" className="test" data-thumbnail="/media/home/flag.png">English</option>
											<option value="en" className="test" data-thumbnail="/media/home/us.png">Us</option>
										</select>
										<div className="lang-select">
											<button className="btn-select" value=""></button>
											<div className="b">
												<ul id="a"></ul>
											</div>
										</div>
									</div>*/}
								</div>
								<div className="header-top-center-content">
									{
										(customreportviewer==true)?<a href='/report-center' className={(tab=='report-center')?'activeheader':''}><img src="/media/teacher/report.png" alt="Images" style={{'margin':'0'}}/> <span>{sMenu.reporting_center}</span></a>:''
									}
									{
									/*
									(this.state.readschoolsetup || this.state.updateschoolsetup) &&
										<a href="/school-information" className={(pathUrl == 'school-information') ? 'activeheader' : ''} style={{ 'marginLeft': '10px' }}><img src="/media/teacher/seting.png" alt="Images" style={{ 'margin': '0' }} /> <span>{sMenu.school_setup} </span></a>
									*/
									}
									
								</div>
								<div className={(this.state.language=='ar')?'header-top-right-content arabic':'header-top-right-content'}>
									{/*Start Custom Flug Select hostis!="localhost" && hostis!="staging" */}
									<div className="header-flag-dropdown" style={{'display':(supportmultilingual!=true)?'none':'','zIndex':'99999999999'}}>
										<select className="vodiapicker">
										{
											(dataLangues!=undefined && dataLangues!=null)?dataLangues.map((dt,i)=>{
												return(
													<option value={dt.value} key={i} className="test" datathumbnail={dt.flag} >{dt.label}</option>
												)
											}):
											<option value="en" className="test" datathumbnail="/media/home/flag.png">English</option>
										}
											
										</select>
										<div className="lang-select">
											<button className="btn-select" value="" onClick={this.toggle.bind(this)}></button>
											<div className="b">
												<ul id="a"></ul>
											</div>
										</div>
									</div>
									{/*End Custom Flug Select*/}
									{
											(this.state.loaderspiner==true)?
											<ReactLoading type="spinningBubbles" color="#00BFFF" height={100} width={100} />:''
										}
									<div className="header-profile-dropdown header-logo-dropdown">
										
										<Dropdown>
										  <Dropdown.Toggle variant="success" id="dropdownMenuButton" className="dropdown-toggle shfocus drop-logo">
												<span className="profile-content">
													<span className="profile-image">
														<img src="/media/home/porfile.png" alt="Images" style={{'display':'none'}}/>
														
														{
															(photo!=='' && photo!==null)?<img src={photo}/>:
															<Avatar size="35" round={true} className="avatar-title" 
																						color={'#00a2a7'} name={this.state.username} />
														}
													</span>
													<span className="profile-text">
														<h5>{(username.length>20)?username.substr(0, 20)+"...":this.state.username}</h5>
															{/*<p>Teacher (ID# {this.state.user_id})</p>*/}
														<p>{(sMenu.teacher_id_).split('29089').join(this.state.user_id)}</p>
													</span>
												</span>
												<div className="down-icon-two"><img src="/media/home/down-arrow.png" alt="Images" /></div>
										  </Dropdown.Toggle>

										  <Dropdown.Menu className="dropdown-menu adjust">
											<Dropdown.Item className={(this.state.language=='ar')?"dropdown-item width-two arabic":"dropdown-item width-two"} href="/teacher">{sMenu.teacher_page}</Dropdown.Item>
											<Dropdown.Item className={(this.state.language=='ar')?"dropdown-item width-two arabic":"dropdown-item width-two"} href="/setting">{sMenu.settings}</Dropdown.Item>
											{(impersonate_data!=undefined && impersonate_data!=null && impersonate_data!='') && (
											<Dropdown.Item className={(this.state.language=='ar')?"dropdown-item width-two arabic":"dropdown-item width-two"} onClick={this.unimpersonate.bind(this)}>
												<div className="">
													<p style={{'color':'#5b9bd1'}}>{sMenu.end_impersonation_mode}</p>
													<p className={(this.state.language=='ar')?"dis-flex-rever":""}><i>{sMenu.impersonator}</i><i>:</i> <i>{this.state.impersonate_info}( {this.state.impersonate_role} )</i></p>
												</div>
											</Dropdown.Item>)
											}
											{
												(dataRole!==undefined && dataRole!==null && dataRole.length>1)?dataRole.map((ti,l)=>{
													return(
													<Dropdown.Item style={{'display':(hostis!="localhost" && hostis!="staging" && hostis!="sa" && parseFloat(ti.role)==1)?'none':''}} className={(this.state.language=='ar')?"dropdown-item width-two log-i-role arabic":"dropdown-item width-two log-i-role"} key={l} onClick={this.user_change_role.bind(this,ti.role)}>{sMenu.log_in_as} {(ti.role!==5)?ti.role_name:'Parent / Mentor'}</Dropdown.Item>
													)
												}):''
											}
											{
												(listAccess!=undefined && listAccess!=null && listAccess!='')?listAccess.map((ti,l)=>{
													return(
													<Dropdown.Item className={(this.state.language=='ar')?"dropdown-item width-two log-i-role arabic":"dropdown-item width-two log-i-role"} key={l} onClick={this.log_sso.bind(this,ti.sso_url)}>{sMenu.access} {ti.source_name} {sMenu.as} {ti.role_name}</Dropdown.Item>
													)
												}):''
											}
											
											<Dropdown.Item className={(this.state.language=='ar')?"dropdown-item width-two arabic":"dropdown-item width-two"} onClick={this.handleDoLogout.bind(this)}>{sMenu.log_out}</Dropdown.Item>
										  </Dropdown.Menu>
										</Dropdown>
										
									</div>
									
									<div className="help-contetn">
										<a onClick={this.zendesksso.bind(this)} target="_blank">
											<img src="/media/home/help.png" alt="Images" />
											
										</a>
									</div>
								</div>
								
							</div>
						</div>
					</div>
					
					<div className="header-bottom-area">
						<div className="container">						
							<div className="mobile-menu-icon">
								<div className="all-p-humber">
									<span></span>
									<span></span>
									<span></span>
									<span></span>
								</div>
							</div>							
							
							<div className="mobile-search-icon">
								<a href="#search"><img src="/media/home/search.png" alt="Images" /></a>
								<div id="search">
									<button type="button" className="close"><i className="fa fa-times" aria-hidden="true"></i></button>
									<form>
										<input type="search" value={this.state.search} placeholder="Search Keyword" onChange={this.handleChange.bind(this)}/>
										<button type="submit" className="btn btn-primary">Search</button>
									</form>
								</div>
							</div>
							
							<div className="header-bottom-content">
								<ul className={(this.state.language=='ar')?"arabic":""}>
									<li>
										<a className={(tab==='home')?"tablinks actives":"tablinks"} href="/home">
											<img src="/media/home/home.png" alt="Images" />
											{sMenu.home}
										</a>
									</li>
									{ (supporticketmanager==true || readroster==true || updateroster==true)?
									<li>
										
										<Dropdown>
										  <Dropdown.Toggle variant="success" id="dropdownMenuButton" className="dropdown-toggle shfocus drop-menu-it">
										  <a className={(tab==='learning' || tab==='rosters')?"tablinks actives":"tablinks"} style={{'marginRight':(this.state.language=='ar')?'':'10px','marginLeft':(this.state.language=='ar')?'10px':''}}>
												<img src="/media/home/developer_board.png" alt="Images" />
												{sMenu.learning}	
												<div className="down-icon-two"><img className={(this.state.language=='ar')?"arabic":""} src="/media/home/down-arrow.png" alt="Images" style={{'right':'-6px'}} /></div>	
											</a>
												
										  </Dropdown.Toggle>

										  <Dropdown.Menu className="dropdown-menu adjust">
											<Dropdown.Item className={(tab==='learning')?"dropdown-item width-two active":"dropdown-item width-two"} href="/section">{sMenu.sections}</Dropdown.Item>
											<Dropdown.Item className={(tab==='rosters')?"dropdown-item width-two active":"dropdown-item width-two"} href={(asiroster==true)?"/asi-rosters":"/rosters"}>{sMenu.rosters}</Dropdown.Item>

										  </Dropdown.Menu>
										</Dropdown>
										
									</li>:
									<li>
										<a className={(tab==='learning')?"tablinks actives":"tablinks"} href="/section">
											<img src="/media/home/developer_board.png" alt="Images" />
											{sMenu.sections}
										</a>
									</li>
									}
									<li>
										<a className={(tab==='schedule')?"tablinks actives":"tablinks"}  href="/schedule">
											<img src="/media/home/icon.png" alt="Images" />
											{sMenu.schedule}
										</a>
									</li>
									<li>
										<a className={(tab==='student-in-teacher')?"tablinks actives":"tablinks"} href="/student-in-teacher">
											<img src="/media/home/s_student.png" alt="Images" />
											{sMenu.students}
										</a>
									</li>
									{
									 //(supporticketmanager==true || supporticketmanager==true || readgradebook==true || updategradebook==true || this.state.gradebook == 1) && (
									 (readgradebook==true || updategradebook==true || this.state.viewgradebook==true) && (
										<li className="gradebook">
											<a className={(tab==='gradebook')?"tablinks actives":"tablinks"} href="/gradebook">
												<img src="/media/home/gradebook.png" alt="Images" />
												{sMenu.gradebook}
											</a>
										</li>
									)}
									{//this.state.attendance == 1 && (
									(this.state.readattendance == true || this.state.updateattendance == true || this.state.viewattendance == true) && (
										<li className="attendance">
											<a className={(tab==='attendances')?"tablinks actives":"tablinks"}  href="/attendances">
												<img src="/media/home/attendance.png" alt="Images" />
												{sMenu.attendance}
											</a>
										</li>
									)}
									{this.state.viewresources == true && (
									<li>
										<a className={(tab==='resources')?"tablinks actives":"tablinks"} href="/resources">
											<img src="/media/home/inbox.png" alt="Images" />
											{sMenu.resources}
											<span style={{'display':'none'}}>14</span>
										</a>
									</li>
									)}
									{this.state.viewmessaging == true && (
										<li>
											<a className={(tab==='messages')?"tablinks actives":"tablinks"} href="/messages-inbox">
												<img src="/media/home/s_inbox.png" alt="Images" />
												{sMenu.inbox}
											</a>
										</li>
									)}
									{(this.state.viewdiscussionforum == true || this.state.participatediscussionforum == true) && (
										<li>
											<Dropdown>
												<Dropdown.Toggle variant="success" id="dropdownMenuButton" className="dropdown-toggle shfocus drop-menu-it">

													<a className={(tab === 'discussion-thread') ? "tablinks actives" : "tablinks"} style={{ 'marginRight': (this.state.language == 'ar') ? '' : '10px', 'marginLeft': (this.state.language == 'ar') ? '10px' : '' }}>
														{this.state.total_count_disccusion > 0 ?
															<label className={`badge badge-danger mr-1`} style={{ color: '#df4445', padding: '3px', fontSize: '9px' }}><p style={{ color: '#ffffff' }}>{this.state.total_count_disccusion}</p></label>
															: 
															<img src="/media/home/class-two.png" alt="Images" />
														}
														{'Discussion'}
														<div className="down-icon-two"><img className={(this.state.language == 'ar') ? "arabic" : ""} src="/media/home/down-arrow.png" alt="Images" style={{ 'right': '-6px' }} /></div>
													</a>

												</Dropdown.Toggle>

												<Dropdown.Menu className="dropdown-menu adjust">
													<Dropdown.Item className="dropdown-item width-two it-dot" href="#" onClick={this.goToDiscussion.bind(this, "")}>
														<p style={{ whiteSpace: 'break-spaces' }}>
															All
														</p>
													</Dropdown.Item>
													{
														(this.state.sections !== undefined && this.state.sections.length > 0) ? this.state.sections.map((s, i) => {
															return (
																<Dropdown.Item key={i} className="dropdown-item width-two it-dot" href="#" onClick={this.goToDiscussion.bind(this, s.value)}>
																	<p style={{whiteSpace: 'break-spaces'}}>
																		{s.label}
																		{s.count_new > 0 &&
																			<label className={`badge badge-danger ml-2`} style={{ color: '#df4445', padding: '3px', fontSize: '9px' }}><p style={{ color: '#ffffff' }}>{s.count_new}</p></label>
																		}
																	</p>
																</Dropdown.Item>
															)
														}) : ''
													}
												</Dropdown.Menu>
											</Dropdown>
										</li>
									)}

									<li>
										<div className="dc_dots_area" >
										  <Dropdown>
											<Dropdown.Toggle variant="success" id="dropdownMenuButton11" className="dropdown-toggle shfocus">
											  <div className="down-icon"><img src="/media/list-resource/ar-dot.png" alt="Images" style={{'right':'-20px','top':'-4px'}} /></div>
											</Dropdown.Toggle>
						
											<Dropdown.Menu className="dropdown-menu">
												<Dropdown.Item className="dropdown-item width-two it-dot" href="/communication-log">Communication Logs</Dropdown.Item>
												{(hostis=="localhost" || hostis=="staging")?<Dropdown.Item className="dropdown-item width-two" href="/request-template"> Request</Dropdown.Item>:''}
											</Dropdown.Menu>
										  </Dropdown>
						
										</div>
									</li>
								</ul>
							</div>
							

						   
							<div className="mobile_main_menu_content">
								<ul id="mu_accordion" className="mu_accordion">
									<li>
										<div className="link">
											<a className="tablinks actives"  href="/home">
												<img src="/media/home/home.png" alt="Images" />
												{sMenu.home}
											</a>
										</div>
									</li>

									{/*<li>
										<div className="link">
											<a className={(tab==='learning')?"tablinks actives":"tablinks"} href="/section">
												<img src="/media/home/developer_board.png" alt="Images" />
												Sections
											</a>
										</div>
									</li>*/}
									{(readroster==true || updateroster==true)?
									<li>
										
										<Dropdown>
										  <Dropdown.Toggle variant="success" id="dropdownMenuButton" className="dropdown-toggle shfocus drop-menu-it">
										  <a className={(tab==='learning' || tab==='rosters')?"tablinks actives":"tablinks"} style={{'marginRight':'10px'}}>
												<img src="/media/home/developer_board.png" alt="Images" />
												{sMenu.learning}	
												<div className="down-icon-two"><img src="/media/home/down-arrow.png" alt="Images" style={{'right':'-6px'}} /></div>	
											</a>
												
										  </Dropdown.Toggle>

										  <Dropdown.Menu className="dropdown-menu adjust">
											<Dropdown.Item className="dropdown-item width-two" href="/section">{sMenu.sections}</Dropdown.Item>
											<Dropdown.Item className="dropdown-item width-two" href={(asiroster==true)?"/rosters":"/rosters"}>{sMenu.rosters}</Dropdown.Item>

										  </Dropdown.Menu>
										</Dropdown>
										
									</li>:
									<li>
										<a className={(tab==='learning')?"tablinks actives":"tablinks"} href="/section">
											<img src="/media/home/developer_board.png" alt="Images" />
											{sMenu.sections}
										</a>
									</li>
									}
									<li>
										<div className="link">
											<a className={(tab==='schedule')?"tablinks actives":"tablinks"}  href="/schedule">
												<img src="/media/home/icon.png" alt="Images" />
												{sMenu.schedule}
											</a>
										</div>
									</li>
									<li>
										<div className="link">
											<a className={(tab==='student-in-teacher')?"tablinks actives":"tablinks"} href="/student-in-teacher">
												<img src="/media/home/s_student.png" alt="Images" />
												{sMenu.students}
											</a>
										</div>
									</li>
									{this.state.viewgradebook==true && (
										<li>
											<div className="link">
												<a className={(tab === 'gradebook') ? "tablinks actives" : "tablinks"} href="/gradebook">
													<img src="/media/home/gradebook.png" alt="Images" />
													{sMenu.gradebook}
												</a>
											</div>
										</li>
									)}
									{this.state.viewattendance == true && (
										<li>
											<div className="link">
												<a className={(tab === 'attendances') ? "tablinks actives" : "tablinks"} href="/attendances">
													<img src="/media/home/attendance.png" alt="Images" />
													{sMenu.attendance}
												</a>
											</div>
										</li>
									)}
									{this.state.viewresources == true && (
										<li>
											<div className="link">
												<a className={(tab === 'resources') ? "tablinks actives" : "tablinks"} href="/resources">
													<img src="/media/home/inbox.png" alt="Images" />
													{sMenu.resources}
													<span style={{ 'display': 'none' }}>14</span>
												</a>
											</div>
										</li>
									)}
									{this.state.viewmessaging == true && (
										<li>
											<div className="link">
												<a className={(tab === 'messages') ? "tablinks actives" : "tablinks"} href="/messages-inbox">
													<img src="/media/home/s_inbox.png" alt="Images" />
													{sMenu.inbox}
												</a>
											</div>
										</li>
									)}
									<li>
										<div className="link">
											<a href="/setting">{sMenu.settings}</a>
										</div>
									</li>
									<li>
										<div className="link">
											<a href="/teacher">{sMenu.teacher_page}</a>
										</div>
									</li>
									<li>
										<div className="link">
											<a href="#" onClick={this.handleDoLogout.bind(this)}>{sMenu.log_out}</a>
										</div>
									</li>
								</ul>
							</div>
						   <ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msg} id="mssage-status"/>
						</div>
					</div>
					
				</header>
				
			)			
		
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
//export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));